import React from 'react';

class VersionsTable extends React.Component {

    /**
     * Custom constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
            versions: []
        };
    }

    /**
     * ReactJS: Method is invoked immediately after a component is mounted.
     */
    componentDidMount() {
        console.log("Load things...")

        fetch("https://storage.googleapis.com/storage/v1/b/schema.cip4.org/o?delimiter=/&prefix=" + this.props.prefix)
            .then(response => {
                if (200 === response.status) {
                    return response.json();
                } else {
                    return null;
                }
            })
            .then(items => {
                var versions = [];

                items.prefixes.reverse().forEach(item => {

                    // version.json is missing
                    var specId = item.match(/[1-2]_([0-9]|x)/g);
                    specId = String(specId).replace("_", ".");

                    const folderName = String(item).slice(0, -1);

                    versions.push({
                        specId: specId,
                        name: this.props.name + " " + specId,
                        xsd: item + this.props.xsdFilename,
                        tar: folderName + ".tar.gz",
                        zip: folderName + ".zip"
                    });

                    this.setState({
                        versions: versions
                    });
                });
            });
    }

    /**
     * Return HTML snippet
     */
    render() {

        // final output
        return (
            <div>
                <table className="table table-hover table-sm">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>XSD Location</th>
                            <th>ZIP Archive</th>
                            <th>TAR Archive</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.versions.map((version, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{version.name}</td>
                                        <td><a href={version.xsd}>{version.xsd}</a></td>
                                        <td><a href={version.zip}>{version.zip}</a></td>
                                        <td><a href={version.tar}>{version.tar}</a></td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default VersionsTable;
