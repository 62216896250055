import React from 'react';
import VersionsTable from './components/VersionsTable';

import pjson from './../package.json';

class Main extends React.Component {

    /**
     * Custom constructor.
     * @param {*} props 
     */
    constructor(props) {
        super(props);

        this.state = {
        };
    }

    /**
     * Return HTML snippet
     */
    render() {

        // final output
        return (
            <div>

                { /* navigation */}
                <nav className="navbar navbar-expand-sm fixed-top" id="navbar">
                    <div className="container-fluid px-0">
                        <div className="navbar-brand"><img className="nav-logo" alt="CIP4 Logo" src={process.env.PUBLIC_URL + '/assets/cip4-organization.png'} /> <span className="cip">CIP4</span> Organization</div>


                        { /* left */}
                        <ul className="navbar-nav me-auto">

                        </ul>

                        { /* right */}
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="#xjdf-schema">XJDF Schema</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#jdf-schema">JDF Schema</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#ptk-schema">PrintTalk Schema</a>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="container" data-bs-spy="scroll" data-bs-target="#navbar" data-bs-offset="0" tabIndex="0">

                    { /* XJDF Schema */}
                    <div className="row my-5" id="xjdf-schema">
                        <div className="col-12">
                            <h2>XJDF Schema</h2>
                            <p>
                                Here are the links to all XJDF Schema versions:
                            </p>
                            <VersionsTable name="XJDF Schema" prefix="jdfschema_2" xsdFilename="xjdf.xsd" />
                        </div>
                    </div>

                    { /* JDF Schema */}
                    <div className="row my-5" id="jdf-schema">
                        <div className="col-12">
                            <h2>JDF Schema</h2>
                            <p>
                                Here are the links to all JDF Schema versions:
                            </p>
                            <VersionsTable name="JDF Schema" prefix="jdfschema_1" xsdFilename="JDF.xsd" />
                        </div>
                    </div>

                    { /* PrintTalk Schema */}
                    <div className="row my-5" id="ptk-schema">
                        <div className="col-12">
                            <h2>PrintTalk Schema</h2>
                            <p>
                                Here are the links to all PrintTalk Schema versions:
                            </p>
                            <VersionsTable name="PrintTalk Schema" prefix="ptk" xsdFilename="PrintTalk.xsd" />
                        </div>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-12">
                        <br /><br /><br /><br /><br />
                    </div>
                </div>

                <div className="footer fixed-bottom"><div className="container-fluid"><div className="d-flex justify-content-center">© All Rights Reserved &nbsp;&nbsp;-&nbsp;&nbsp; v{pjson.version}</div></div></div>

            </div>
        );
    }
}

export default Main;
